<template>
  <div>
    <vue-university />
  </div>
</template>
<script>
import universityConfig from '@/components/university/Index.vue'

export default {
  components: {
    'vue-university': universityConfig,
  },
}
</script>

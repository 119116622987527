<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-tabs
          vertical
        >
          <b-card>
            <b-tab active>
              <template #title>
                <feather-icon icon="UserIcon" />
                <span>Configuración de contrato</span>
              </template>
              <ContratoConfig />
            </b-tab>
          </b-card>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {
  BRow, BCol, BTabs, BTab, BContainer, BCard,
} from 'bootstrap-vue'
import ContratoConfig from '@/views/opcion/Contratos.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BContainer,
    BCard,
    ContratoConfig,
  },
}
</script>
